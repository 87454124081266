<template>
    <div class="wraper">

        <div class="wrap-mainer">
            <div class="bgc sidebar">
                <div class="samplelefttit">部门人员列表</div>
                <div class="sidebarmain">
                    <a-tree :tree-data="node_list" :expandedKeys.sync="expandedKeys" :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="showLine" :show-icon="showIcon" @select="onSelect">
                        <a-icon slot="icon" type="carry-out"/>
                    </a-tree>
                </div>
                <div class="addbutton">
                    <div class="addbuttonbtn" @click="createClick(0)">
                        添加分类
                    </div>
                </div>
            </div>
            <div class="wrap-container">
                <!-- 创建、编辑部门 -->
                <div class="createsmaplewrap" v-show="createbumen">
                    <div class="createsmaple1">
                        <div class="createsmapletit">{{ do_type?'编辑':'创建' }}部门</div>
                        <div v-if="do_type" style="margin-top: 20px; text-align: left; background: #FAFAFA; padding:10px 20px;">
                            <a-button @click="createNewList()" type="primary">
                                创建部门
                            </a-button>
                            <a-button type="primary" class="ml20" @click="createNewNode()">
                                创建人员
                            </a-button>
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick" style="float: right">
                                <template slot="title">
                                    确认删除该项？
                                </template>
                                <a-button type="danger" class="ml20">
                                    删除
                                </a-button>
                            </a-popconfirm>
                        </div>
                    </div>
                    <div class="mt20">
                        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" style="margin-left: 150px">
                            <a-form-model-item label="名称">
                                <a-input v-model="form.name" class="w300"/>
                            </a-form-model-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                <a-button type="primary" html-type="submit" @click="doClick()" style="width: 120px; height: 40px">
                                    保存
                                </a-button>
                            </a-form-item>
                        </a-form-model>
                    </div>
                </div>

                <div v-show="editbumen">
                    <div>
                        <div class="createsmaple1" style="width: 100%">
                            <div class="createsmapletit">{{ do_type?'编辑':'创建' }}人员信息</div>
                            <div v-if="do_type" style="margin-top: 20px; text-align: right; background: #FAFAFA; padding:10px 20px;">
                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick">
                                    <template slot="title">
                                        确认删除该项？
                                    </template>
                                    <a-button type="danger" class="ml20">
                                        删除
                                    </a-button>
                                </a-popconfirm>
                            </div>
                        </div>
                    </div>
                    <a-tabs type="card" @change="callback" style="margin-top: 20px">
                        <a-tab-pane key="1" tab="基本资料">
                            <div class="mt20">
                                <a-form-model :model="form1" :label-col="labelCol" :wrapper-col="wrapperCol">
                                    <a-form-model-item label="账号">
                                        <a-input v-model="form1.account" class="w300"/>
                                    </a-form-model-item>
                                    <a-form-model-item label="密码">
                                        <a-input type="text" v-model="form1.password" class="w300"/>
                                        <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="resetPasswordClick">
                                            <template slot="title">
                                                您确定要重置密码？
                                            </template>
                                            <a-button type="primary" style="margin-left: 10px" v-if="do_type">重置
                                            </a-button>
                                        </a-popconfirm>
                                    </a-form-model-item>
                                    <a-form-model-item label="姓名">
                                        <a-input v-model="form1.name" class="w300"/>
                                    </a-form-model-item>
                                    <a-form-model-item label="电话">
                                        <a-input v-model="form1.phone" class="w300"/>
                                    </a-form-model-item>
                                    <a-form-model-item label="编号">
                                        <!--                                        <a-input v-model="form1.serial_num" class="w300"/>-->
                                        <a-input ref="userNameInput" v-model="form1.serial_num" placeholder="" class="w300">
                                            <a-tooltip slot="suffix" title="使用默认规则" @click="nummoren()">
                                                <a-icon type="unordered-list" style="color: rgba(0,0,0,.45)"/>
                                            </a-tooltip>
                                        </a-input>
                                        <a-button type="primary" @click="showModal()" style="margin-left: 10px">选择编号规则
                                        </a-button>
                                    </a-form-model-item>

                                    <a-form-model-item label="权限">
                                        <!--                                                                                                                        {{ typeof form1.auth}}-->
                                        <!--                                                                                                                        {{  form1.auth}}-->
                                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="form1.auth_ids" :options="type_list" placeholder="请选择" style="width: 200px"/>
                                        <span class="ant-form-text" @click="getStypenew()" style="margin-left: 10px; cursor: pointer"><a-icon type="sync"/></span>
                                        <a-button type="primary" @click="showDrawer">创建权限组</a-button>
                                    </a-form-model-item>
                                    <a-form-model-item label="状态">
                                        <a-radio-group name="radioGroup" :default-value="1" v-model="form1.status">
                                            <a-radio :value="1">可用</a-radio>
                                            <a-radio :value="2">不可用</a-radio>
                                        </a-radio-group>
                                    </a-form-model-item>
                                    <a-form-model-item label="部门负责人">
                                        <a-radio-group name="radioGroup" :default-value="0" v-model="form1.responsible">
                                            <a-radio :value="0">是</a-radio>
                                            <a-radio :value="1">否</a-radio>
                                        </a-radio-group>
                                    </a-form-model-item>
                                    <a-form-model-item label="备注">
                                        <a-textarea v-model="form1.remark" style="width: 400px; height: 100px"/>
                                    </a-form-model-item>
                                    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                        <a-button type="primary" @click="doClick1()" style="width: 120px; height: 40px">
                                            保存
                                        </a-button>
                                    </a-form-item>
                                </a-form-model>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="个人能力及证书" v-if="do_type">
                            <div>
                                <div style="margin-top: 10px">
                                    <table class="layui-table">
                                        <tr>
                                            <th>编号</th>
                                            <th>名称</th>
                                            <th>检测项目</th>
                                            <th>检测方法</th>
                                            <th>发证日期</th>
                                            <th>批准日期</th>
                                            <th>有效截止日期</th>
                                            <!--                                            <th>备注</th>-->
                                            <th>操作</th>
                                        </tr>
                                        <tr v-for="( item,key) in tableData" :key="key">
                                            <td>
                                                <a-input v-model="item.serial_num" class="w200"/>
                                            </td>
                                            <td>
                                                <a-input v-model="item.name" style="width: 200px"></a-input>
                                            </td>
                                            <td>

                                                <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'children' }" :options="options" placeholder="请选择" style="width: 200px" v-model="item.det_item_ids" @change="onclickdetitemids(key)"/>
                                            </td>
                                            <td>

                                                <a-select allowClear show-search option-filter-prop="children" v-decorator="['fileresults',{ rules: [{ required: true, message: '请选择标记结果!' }] },]" v-model="item.det_method_id" placeholder="请选择" style="width: 400px">
                                                    <a-select-option v-for="(i,k ) in detmethods[key]" :key="k" :value="i.id" :title="i.name">
                                                        {{ i.name}}
                                                    </a-select-option>
                                                </a-select>
                                            </td>
                                            <td>
                                                <a-date-picker v-model="item.send_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 120px"/>
                                            </td>
                                            <td>
                                                <a-date-picker v-model="item.approve_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 120px"/>
                                            </td>
                                            <td>
                                                <a-date-picker v-model="item.expiration_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 120px"/>
                                            </td>
                                            <!--                                            <td>-->
                                            <!--                                                <a-input v-model="item.remark" class="w200"/>-->
                                            <!--                                            </td>-->
                                            <td>
                                                <div style="width: 100px; display: flex">
                                                    <a-button type="primary" size="small" @click="saveItem(item)" style="margin-right: 10px">
                                                        保存
                                                    </a-button>
                                                    <a-button type="danger" size="small" @click="delItem(item)">删除
                                                    </a-button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a-input v-model="create_data.serial_num" placeholder="" class="w200"/>
                                            </td>
                                            <td>
                                                <a-input v-model="create_data.name" placeholder="" style="width: 200px"/>
                                            </td>
                                            <td>
                                                <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'children' }" :options="options1" placeholder="请选择" style="width: 200px" v-model="create_data.det_item_ids" @change="onclickdetitemids1()"/>
                                            </td>
                                            <td>
                                                <a-select allowClear show-search option-filter-prop="children" v-decorator="['fileresults',{ rules: [{ required: true, message: '请选择标记结果!' }] },]" v-model="create_data.det_method_id" placeholder="请选择" style="width: 400px">
                                                    <a-select-option v-for="(item,key ) in detmethods1" :key="key" :value="item.id" :title="item.name">
                                                        {{ item.name}}
                                                    </a-select-option>
                                                </a-select>
                                            </td>
                                            <td>
                                                <a-date-picker v-model="create_data.send_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 120px"/>
                                            </td>
                                            <td>
                                                <a-date-picker v-model="create_data.approve_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 120px"/>
                                            </td>
                                            <td>
                                                <a-date-picker v-model="create_data.expiration_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 120px"/>
                                            </td>
                                            <!--                                            <td>-->
                                            <!--                                                <a-input v-model="create_data.remark" class="w200"/>-->
                                            <!--                                            </td>-->
                                            <td>
                                                <a-button type="primary" size="small" @click="Create_zhegnshu()">增加
                                                </a-button>
                                            </td>
                                        </tr>

                                    </table>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="修改密码" v-if="do_type">
                            <div class="mt20">
                                <a-form-model :model="passwordform" :label-col="labelCol" :wrapper-col="wrapperCol">
                                    <a-form-model-item label="新密码">
                                        <a-input v-model="passwordform.password" class="w300"/>
                                    </a-form-model-item>
                                    <a-form-model-item label="确认新密码">
                                        <a-input v-model="passwordform.confirm_password" class="w300"/>
                                    </a-form-model-item>
                                    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                        <a-button type="primary" @click="resetPassword()" style="width: 120px; height: 40px">
                                            保存
                                        </a-button>
                                    </a-form-item>
                                </a-form-model>
                            </div>
                        </a-tab-pane>


                        <a-tab-pane key="4" tab="审核资质" v-if="do_type">
                            <div class="createsmaplewrap">
                                <div class="mt20">
                                    <div style="width: 500px; margin: 0 auto; background: rgb(254 253 253); padding: 50px; border: 1px dashed #eee">
                                        <a-checkbox v-model="record_auth">
                                            原始记录单审核权限
                                        </a-checkbox>
                                        <br>
                                        <br>
                                        <a-checkbox v-model="report_auth">
                                            报告审核权限
                                        </a-checkbox>
                                        <br>
                                        <br>
                                        <a-checkbox v-model="acid_auth">
                                            核酸审核权限
                                        </a-checkbox>
                                        <div style="margin-top: 50px">
                                            <a-button type="primary" @click="Setdandaauth()" style="width: 120px; height: 40px">
                                                确定
                                            </a-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="5" tab="在岗状态" v-if="do_type">
                            <div class="createsmaplewrap">
                                <div class="mt20">
                                    <div style="width: 600px; margin: 0 auto; background: rgb(254 253 253); padding: 50px; border: 1px dashed #eee">
                                        <a-radio-group style="margin-left: 100px" v-model="job_status">
                                            <a-radio :value="1">
                                                在岗
                                            </a-radio>
                                            <a-radio :value="2">
                                                离岗
                                            </a-radio>
                                            <a-radio :value="3">
                                                请假
                                            </a-radio>
                                        </a-radio-group>
                                        <div style="margin-top: 30px">
                                            <a-form-model :model="zginfo" layout="inline" :label-col="labelCol" :wrapper-col="wrapperCol">
                                                <a-form-model-item label="请假时间" style="width: 500px" v-if="job_status===3">
                                                    <a-date-picker v-model="zginfo.leave_data" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                                </a-form-model-item>
                                                <a-form-model-item label="预计回岗时间" style="width: 500px; margin-top: 20px" v-if="job_status===2 || job_status===3 ">
                                                    <a-date-picker v-model="zginfo.return_data" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                                </a-form-model-item>
                                                <p style="line-height: 26px; color: #999999; margin-top: 10px; margin-left: 120px" v-if="job_status===2 || job_status===3 ">
                                                    无预计时间可以留空，不在岗的人员不在任务分配列表显示，<br>设置预回岗时间后，到达该时间后人员自动变为在岗状态</p>
                                            </a-form-model>
                                        </div>
                                        <div style="margin-top: 10px; margin-left: 125px">
                                            <a-button type="primary" @click="Setjobstatus()" style="width: 120px; height: 40px">
                                                保存
                                            </a-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="6" tab="在岗变化记录" v-if="do_type">
                            <div class="createsmaplewrap" style="position: relative">
                                <div style="width: 100%; min-height: 500px; margin-top: 10px">
                                    <a-empty class="empty" v-if="jilutableData.length===0" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%)"/>
                                    <table class="layui-table">
                                        <thead>
                                        <tr>
                                            <th>开始时间</th>
                                            <th>预计回岗时间</th>
                                            <th>实际回岗时间</th>
                                            <th>类型</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="( item,key) in jilutableData" :key="key">
                                            <td>{{item.leave_data}}</td>
                                            <td>{{item.return_data}}</td>
                                            <td><span v-if="item.job_status==1">{{item.create_time}}</span></td>
                                            <td>{{wordType(item.job_status)}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                                </div>
                            </div>
                        </a-tab-pane>

                        <a-tab-pane key="7" tab="登录日志" v-if="do_type">
                            <div class="createsmaplewrap" style="position: relative">
                                <div style="width: 100%; min-height: 500px; margin-top: 10px">
                                    <a-empty class="empty" v-if="loginlogtableData.length===0" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%)"/>
                                    <table class="layui-table">
                                        <thead>
                                        <tr>
                                            <th>登录时间</th>
                                            <th>登录IP</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="( item,key) in loginlogtableData" :key="key">
                                            <td>{{item.login_time}}</td>
                                            <td>{{item.login_ip}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <a-pagination :current="page1" :pageSize="pagesize1" @change="onChange2" :total="count1" v-if="count1>pagesize1"/>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="8" tab="签名图片" v-if="do_type">
                            <div style="margin: 0 auto; margin-left: 350px; margin-top: 100px">
                                <div>
                                    <div class="imgdiv">

                                        <input type="file" @change="uploadChange" class="imgdivfile">

                                        <a-icon v-if="file_url == '' || file_url == null" type="plus" class="addjia"/>

                                        <div v-if="file_url != ''" class="fileimg">
                                            <img :src="file_url" alt="">
                                        </div>
                                        <div v-if="file_url != ''" style=" margin-left: 60px; margin-top: 20px; position: absolute; bottom: -140px">
                                            点击图片更换签名
                                        </div>
                                    </div>
                                    <!--                                    <div>{{filename}}</div>-->
                                    <a-button type="primary" @click="uploadClick()" class="addbtn" style="margin-top: 30px">
                                        确定上传
                                    </a-button>
                                    <!--                                    <div>-->
                                    <!--                                        <input type="file" @change="uploadChange">-->
                                    <!--                                        <a-input v-model="filename"></a-input>-->
                                    <!--                                        <a-button @click="uploadClick()">确定上传</a-button>-->
                                    <!--                                        <div>-->
                                    <!--                                            <a-input v-model="file_url"></a-input>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->

                                </div>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
        </div>
        <Quanxian :getStype="getStype" :close="onCloseDrawer" :visible="drawer_visible"></Quanxian>
        <Numberrulelistuser :callback="pushOrder" :ok="modalClose" :cancel="modalClose" :visible="modal_visible"></Numberrulelistuser>
    </div>
</template>
<script>
    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    import Quanxian from '../../../components/drawer/qunaxianpage/index.vue';
    import Numberrulelistuser from '../numberrulelistuser/index.vue';

    export default {
        props: [],
        components: {
            Quanxian, Numberrulelistuser
        },
        data() {
            return {
                file: false,
                filename: '',
                file_url: '',
                page: 1,
                count: 0,
                pagesize: 0,
                page1: 1,
                count1: 0,
                pagesize1: 0,
                jilutableData: [],
                detmethods: [],
                detmethods1: [],
                loginlogtableData: [],
                zginfo: {
                    return_data: '',
                    leave_data: ''
                },
                modal_visible: false,
                record_auth: false,
                report_auth: false,
                acid_auth: false,
                job_status: 1,
                radioStyle: {
                    display: 'block',
                },
                zaigang: {
                    qingjia_time: ''
                },
                value: 1,
                create_data: {
                    send_time: '',
                    approve_time: '',
                    expiration_time: '',
                    det_item_ids: []
                },
                info: {},
                tableData: [],
                expandedKeys: [],
                type_list: [],
                danda_ids: [],
                openArr: [0],
                do_type: false,
                node_list: [],
                drawer_visible: false,
                createbumen: false,
                editbumen: false,
                showLine: true,
                showIcon: false,
                labelCol: {span: 6},
                wrapperCol: {span: 14},
                form: {},
                form1: {
                    auth: []
                },
                options: [],
                options1: [],
                jiangcenengli: [],
                p_info: false,
                pid: 0,
                fazheng_time: '',
                pizhun_time: '',
                youxiao_time: '',
                passwordform: {
                    password: '',
                    confirm_password: '',
                },
                headers: {
                    authorization: 'authorization-text',
                },
            };
        },
        watch: {
            $route: {
                handler() {
                    this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.page1 = Number(this.$route.query.page1 ? this.$route.query.page1 : 1)
                    this.Dandaalllist()
                    this.mountedDo()
                    this.Get_danda_qualify()
                    this.Get_dandajob_log()
                    this.Loginloglist()
                },
                deep: true
            }
        },
        mounted() {
            this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.page1 = Number(this.$route.query.page1 ? this.$route.query.page1 : 1)
            this.Dandaalllist()
            this.mountedDo()
            this.Get_danda_qualify()
            this.Bindsamplelist()
            this.Bindsamplelist1()
            this.getStype()
            this.Get_dandajob_log()
            this.Loginloglist()
        },
        methods: {
            uploadClick() {
                this.$sa0.post({
                    url: this.$api('Update_danda_tagurl'),
                    data: {
                        danda_id: this.pid,
                        tag_url: this.file_url,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('保存成功')
                            this.mountedDo()

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            uploadChange(e) {
                this.file = e.target.files[0];
                this.filename = e.target.files[0].name;
                let param = new FormData()
                param.append('file', this.file)
                param.append('filename', this.filename)
                this.$sa0.file({
                    url: this.$api('Upload_pic'),
                    data: param
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        error: () => {
                            layer.msg('上传失败')
                        },
                        then: (res) => {
                            layer.msg('上传成功')
                            this.file_url = res.data.url
                        }
                    })
                })
            },
            nummoren() {
                this.$sa0.post({
                    url: this.$api('Get_default_serialnumber'),
                    data: {
                        num_type: 2,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.form1.serial_num = response.data.serialnumber;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            resetPassword() {
                if (this.passwordform.password === this.passwordform.confirm_password) {
                    this.$sa0.post({
                        url: this.$api('Edit_password'),
                        data: {
                            admin_id: this.pid,
                            password: this.passwordform.password,
                            confirm_password: this.passwordform.confirm_password,
                        }
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                layer.msg('修改成功')
                                this.passwordform = {
                                    password: '',
                                    confirm_password: '',
                                }
                                this.getItemInfo()
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                } else {
                    layer.msg('两次密码不一致')
                }
            },
            resetPasswordClick() {
                this.$sa0.post({
                    url: this.$api('Reset_password'),
                    data: {
                        admin_id: this.pid,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.open({
                                title: '新密码',
                                type: 1,
                                area: ['385px', '230px'], //宽高
                                content: '您重置密码为：' + response.data.password
                            })
                            this.getItemInfo()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            saveItem(item) {
                let data = item
                // data.det_item_ids = item.det_item_ids[item.det_item_ids.length - 1];
                console.log('data---------------------------------', JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Save_danda_qualify'),
                    data: data,
                }).then((response) => {
                    console.log(data)
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_danda_qualify()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            delItem(item) {
                let data = {
                    id: item.id,
                };
                this.$sa0.post({
                    url: this.$api('Delete_danda_qualify'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_danda_qualify()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Create_zhegnshu() {
                let data = {
                    serial_num: this.create_data.serial_num,
                    name: this.create_data.name,
                    send_time: this.create_data.send_time,
                    approve_time: this.create_data.approve_time,
                    expiration_time: this.create_data.expiration_time,
                    remark: this.create_data.remark,
                    det_item_ids: this.create_data.det_item_ids,
                    // det_item_ids: this.create_data.det_item_ids[this.create_data.det_item_ids.length - 1],
                    det_method_id: this.create_data.det_method_id,
                    danda_id: this.pid,
                };
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Create_danda_qualify'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_danda_qualify()
                            this.create_data = {
                                serial_num: '',
                                name: '',
                                send_time: '',
                                approve_time: '',
                                expiration_time: '',
                                remark: '',
                                danda_id: '',
                                det_item_ids: [],
                                det_method_id: '',
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            getStype(ids = false) {
                this.$sa0.post({
                    url: this.$api('Authalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.type_list = response.data.list
                            // console.log('this.type_list', this.type_list)
                            // console.log('idsidsidsidsidsidsidsidsidsidsidsidsidsidsids', ids)
                            if (ids) {
                                this.$set(this.form1, 'auth_ids', ids)
                            } else {
                                this.form1.auth_ids = []
                                this.mountedDo()
                            }
                            this.onCloseDrawer()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            getStypenew() {
                this.$sa0.post({
                    url: this.$api('Authalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.type_list = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 获取个人能力和证书列表
            Get_danda_qualify() {
                console.log('this.pid--------------', this.pid)
                console.log(this.id)
                this.$sa0.post({
                    url: this.$api('Get_danda_qualify'),
                    data: {
                        danda_id: this.pid,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.detmethods = []
                            this.tableData = response.data.list.map((item) => {
                                let ids = []
                                if (item.det_item_ids) {
                                    ids = (item.det_item_ids.toString()).split(',')
                                }
                                let i = ids.map((ii) => {
                                    return Number(ii)
                                })
                                // det_item_ids
                                // det_method_id
                                item.det_item_ids = i;
                                this.detmethods.push([])
                                return item;
                            });
                            console.log('this.tableData', this.tableData)
                            setTimeout(() => {
                                for (let i = 0; i < this.tableData.length; i++) {
                                    this.onclickdetitemids(i)
                                }
                            })
                            console.log('this.tableData-------------', this.tableData)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 获取检测能力列表

            Bindsamplelist() {
                this.$sa0.post({
                    url: this.$api('DetitemByzz'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.options = response.data.list;
                            console.log('this.options------------------', this.options)
                            console.log(JSON.stringify(response.data.list))
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            onclickdetitemids(key) {
                console.warn('----------------------------??---------', key)
                let ids = this.tableData[key].det_item_ids
                if (ids.length > 0) {
                    let id = ids[ids.length - 1]
                    this.$sa0.post({
                        url: this.$api('DetmethodByzz'),
                        data: {
                            det_item_id: id
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                console.warn(key)
                                this.$set(this.detmethods, key, response.data.list)
                                console.warn('this.detmethods-------------------------------', this.detmethods[key])
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                }
            },


            onclickdetitemids1() {
                let ids = this.create_data.det_item_ids
                let id = ids[ids.length - 1]
                console.log(id)
                this.$sa0.post({
                    url: this.$api('DetmethodByzz'),
                    data: {
                        det_item_id: id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.detmethods1 = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            Bindsamplelist1() {
                this.$sa0.post({
                    url: this.$api('DetitemByzz'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.options1 = response.data.list;
                            console.log('this.options------------------', this.options)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Dandaalllist() {
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.node_list = response.data.list;

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            createClick(pid) {
                if (Number(this.pid) === Number(pid)) return false;
                this.$router.push('/renyuanguanli/index/' + pid)
            },
            mountedDo() {
                this.pid = this.$route.params.id
                if (this.pid == 0) {
                    this.form = {
                        id: '0',
                        name: '',
                        type: '1',
                        remark: '',
                        pid: this.pid,
                        account: '',
                        password: '',
                        auth: [],
                        status: 1,
                        responsible: '',
                        phone: '',
                        serial_num: '',
                    };
                    this.form1 = {
                        id: '0',
                        name: '',
                        type: '2',
                        remark: '',
                        pid: this.pid,
                        account: '',
                        password: '',
                        auth: [],
                        status: 1,
                        responsible: '',
                        phone: '',
                        serial_num: '',
                    };
                    this.createbumen = true;
                    this.editbumen = false;
                    this.do_type = false;
                } else {
                    console.log('getItemInfo---------------')
                    this.getItemInfo()
                }
            },
            getItemInfo() {
                this.do_type = true
                console.log('-------------------------')
                this.$sa0.post({
                    url: this.$api('Get_danda_detail'),
                    data: {
                        id: this.pid
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            console.log('response.data', response.data)
                            this.form_word = response.data.danda;
                            this.expandedKeys = ((this.form_word.danda_ids).toString()).split(',').map((item) => {
                                return Number(item)
                            })
                            console.log('expandedKeys', this.expandedKeys)
                            console.log('this.form_word.auth_ids', this.form_word.auth_ids)
                            this.openArr = [this.form_word.pid]
                            if (this.form_word.type == 1) {
                                this.createbumen = true;
                                this.editbumen = false;
                            } else {
                                this.createbumen = false;
                                this.editbumen = true;
                            }
                            console.warn('this.form_word.auth_ids', this.form_word.auth_ids)
                            this.form1 = {
                                id: this.form_word.id,
                                danda_id: this.form_word.danda_id,
                                name: this.form_word.name,
                                type: this.form_word.type,
                                pid: this.form_word.pid,
                                remark: this.form_word.remark,
                                account: this.form_word.account,
                                password: this.form_word.password,
                                // auth_ids: this.form_word.auth_ids.split(',').map((item) => {
                                //     return Number(item)
                                // }),

                                auth_ids: ((this.form_word.auth_ids).toString()).split(',').map((item) => {
                                    return Number(item)
                                }),

                                auth: this.form_word.auth,
                                status: this.form_word.status,
                                responsible: this.form_word.responsible,
                                phone: this.form_word.phone,
                                serial_num: this.form_word.serial_num,
                            }
                            this.file_url = this.form_word.tag_url
                            this.form = {
                                id: this.form_word.id,
                                danda_id: this.form_word.danda_id,
                                name: this.form_word.name,
                                type: this.form_word.type,
                                pid: this.form_word.pid,
                                remark: this.form_word.remark,
                                account: this.form_word.account,
                                password: this.form_word.password,
                                status: this.form_word.status,
                                responsible: this.form_word.responsible,
                                phone: this.form_word.phone,
                                serial_num: this.form_word.serial_num,
                            }
                            // 审核资质
                            this.record_auth = Number(response.data.danda.record_auth) === 1 ? false : true
                            this.report_auth = Number(response.data.danda.report_auth) === 1 ? false : true
                            this.acid_auth = Number(response.data.danda.acid_auth) === 1 ? false : true

                            //需改人员在岗状态

                            this.job_status = Number(this.form_word.job_status)
                            this.zginfo = {
                                return_data: this.form_word.return_data,
                                leave_data: this.form_word.leave_data,
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            doClick() {
                console.log(this.form)
                let data = {
                    danda_id: this.do_type ? this.form.id : '0',
                    type: '1',
                    is_top: this.form.is_top,
                    name: this.form.name,
                    pid: this.form.pid,
                    remark: this.form.remark,
                    account: this.form.account,
                    password: this.form.password,
                    auth: '0',
                    status: '1',
                    responsible: '0',
                    phone: this.form.phone,
                    serial_num: this.form.serial_num,
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.do_type ? this.$api('Update_danda') : this.$api('Create_danda'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            if (this.do_type) {
                                this.Dandaalllist()
                                this.mountedDo()
                            } else {
                                this.$router.push('/renyuanguanli/index/' + res.data.id)
                            }
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            doClick1() {
                let data = {
                    danda_id: this.do_type ? this.form1.id : '0',
                    type: '2',
                    name: this.form1.name,
                    pid: this.form1.pid,
                    remark: this.form1.remark,
                    account: this.form1.account,
                    password: this.form1.password,
                    auth: this.form1.auth_ids[this.form1.auth_ids.length - 1],
                    auth_ids: this.form1.auth_ids[this.form1.auth_ids.length - 1],
                    status: this.form1.status,
                    responsible: this.form1.responsible,
                    phone: this.form1.phone,
                    serial_num: this.form1.serial_num,
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.do_type ? this.$api('Update_danda') : this.$api('Create_danda'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            if (this.do_type) {
                                this.Dandaalllist()
                                this.mountedDo()
                            } else {
                                this.$router.push('/renyuanguanli/index/' + res.data.id)
                            }
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            createNewList() {
                this.form = {
                    id: '0',
                    type: '1',
                    pid: this.pid,
                    name: '',
                    remark: '',
                    status: '1',
                    is_top: '1',
                    account: '',
                    auth: '1',
                    phone: '',
                    serial_num: '',
                    responsible: '0',
                };
                this.createbumen = true;
                this.editbumen = false;
                this.do_type = false;
            },

            createNewNode() {
                this.form1 = {
                    id: '0',
                    type: '2',
                    pid: this.pid,
                    name: '',
                    remark: '',
                    status: '1',
                    is_top: '2',
                    account: '',
                    auth: [],
                    phone: '',
                    serial_num: '',
                    responsible: '1',
                };
                this.createbumen = false;
                this.editbumen = true;
                this.do_type = false;
            },
            delClick() {
                this.$sa0.post({
                    url: this.$api('Delete_danda'),
                    data: {
                        admin_id: this.pid,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Dandaalllist()
                            this.$router.push('/renyuanguanli/index/0')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 定义 关闭抽屉时的 函数 编辑

            onCloseDrawer() {
                this.drawer_visible = false;
            },

            // 定义 打开抽屉时的 函数 编辑

            showDrawer() {
                this.create_time = new Date() / 1;
                this.create_info = this.info
                this.drawer_visible = true;
            },

            // onChange(date, dateString) {
            //     console.log(date, dateString);
            // },

            onSelect(selectedKeys, info) {
                if (selectedKeys.length == 0) return false;
                if (Number(this.pid) === Number(selectedKeys[0])) return false;
                this.$router.push('/renyuanguanli/index/' + selectedKeys[0])
            },
            onChange1(value) {
                console.log(value);
            },
            onChang2(e) {
                console.log(`checked = ${e.target.checked}`);
            },

            callback(key) {
                console.log(key);
            },
            onOk(value) {
                console.log('onOk: ', value);
            },
            onChangetime(key, value, dateString, e) {
                this.tableData[key][`${e}`] = dateString;
            },
            onChangetime1(value, dateString, e) {
                this.create_data[`${e}`] = dateString;
            },
            getCurrentStyle(current, today) {
                const style = {};
                if (current.date() === 1) {
                    style.border = '1px solid #1890ff';
                    style.borderRadius = '50%';
                }
                return style;
            },

            pushOrder(order) {
                this.form1.serial_num = order
                this.modalClose();
            },


            modalClose() {
                this.modal_visible = false
            },
            // 定义 打开对话框时的 函数
            showModal() {
                this.modal_visible = true
            },

            Setdandaauth() {
                this.$sa0.post({
                    url: this.$api('Set_danda_auth'),
                    data: {
                        danda_id: this.pid,
                        record_auth: this.record_auth ? 2 : 1,
                        report_auth: this.report_auth ? 2 : 1,
                        acid_auth: this.acid_auth ? 2 : 1,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功')
                            // this.Get_danda_detail()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Setjobstatus() {
                console.log(JSON.stringify({
                    danda_id: this.pid,
                    job_status: this.job_status,
                    return_data: this.zginfo.return_data,
                    leave_data: this.zginfo.leave_data,
                }))
                this.$sa0.post({
                    url: this.$api('Set_danda_job'),
                    data: {
                        danda_id: this.pid,
                        job_status: this.job_status,
                        return_data: this.zginfo.return_data,
                        leave_data: this.zginfo.leave_data,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功')
                            this.Get_dandajob_log()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 在岗编号记录
            Get_dandajob_log() {
                this.$sa0.post({
                    url: this.$api('Get_dandajob_log'),
                    data: {
                        page: this.page,
                        danda_id: this.pid,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.jilutableData = response.data.result.list
                            console.log('123_______________', response.data.result.list)
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 登录日志记录
            Loginloglist() {
                this.$sa0.post({
                    url: this.$api('Loginloglist'),
                    data: {
                        page: this.page1,
                        danda_id: this.pid,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.loginlogtableData = response.data.return.list
                            this.count1 = Number(response.data.return.count);//分页
                            this.pagesize1 = Number(response.data.return.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page
                    }
                })
            },
            // 分页
            onChange2(page1) {
                this.$router.push({
                    query: {
                        page1: page1
                    }
                })
            },
            // 类型转换
            wordType(status) {
                let type_arr = ['', '回岗', '离岗', '请假']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status)]
                } else {
                    return status
                }
            },
        },
    };
</script>

<style>
    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .sidebar{ width: 200px; display: flex; flex-direction: column}

    .sidebarmain{ flex: 1; overflow-y: auto}

    .wrap-container{ flex: 1; overflow-y: auto}

    .addbutton{ width: 100%; text-align: center; bottom: 0; height: 40px; background: #1890ff}

    .addbuttonbtn{ width: 100%; color: #ffffff; line-height: 40px; cursor: pointer; height: 40px}

    .bgc{ margin-right: 20px; border: 1px solid #eeeeee;}

    .samplelefttit{ background: #f9f9f9; padding: 10px; border-bottom: 1px solid #eeeeee; font-weight: bold }

    .createsmaplewrap{ width: 1000px; }

    .createsmaple{ width: 1000px; display: flex; justify-content: space-between}

    .createsmapletit{ font-size: 20px; font-weight: bold}

    .mt20{ margin-top: 20px}

    .w300{ width: 200px}

    .w200{ width: 100px}

    .ml20{ margin-left: 20px}


    .w140{ width: 140px}

    .layui-layer-content{ padding: 20px;}

    .avatar-uploader > .ant-upload{
        width: 250px;
        height: 250px;
        }

    .ant-upload-select-picture-card i{
        font-size: 32px;
        color: #999;
        }

    .ant-upload-select-picture-card .ant-upload-text{
        margin-top: 8px;
        color: #666;
        }

    .ant-upload.ant-upload-select-picture-card > .ant-upload{
        padding: 80px !important;
        }

    .ant-upload.ant-upload-select-picture-card > .ant-upload{ width: 250px; height: 250px}

    .imgdiv{ width: 250px; height: 250px; background: #FAFAFA; border: 1px solid #eeeeee; position: relative; text-align: center; line-height: 250px; }

    .imgdivfile{ width: 100%; height: 100%; position: absolute; top: 0; left: 0; opacity: 0; z-index: 99999999}

    .addjia{ font-size: 5rem; color: #cccccc }

    .addbtn{ margin: 20px auto; margin-left: 75px}

    .fileimg{ position: absolute; top: 0; left: 0; left: 50%; top: 50%; transform: translate(-50%, -50%); max-height: 250px; max-width: 250px}


</style>
